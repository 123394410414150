import Cookies from 'js-cookie';
import { isFunctionalCookiesBlockedWithConsent } from '@honda-canada/design-system-react';

const RETURNING_USER_COOKIE = 'Honda.Cookie.User';
const path = window.location.pathname;

const isHomePagePath = _path => {
  const pathValue = _path?.replaceAll('/', '');
  return pathValue === '' || pathValue === 'en' || pathValue === 'fr';
};

const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;

const toggleHondaFamilyHeader = ({ cookieConsentProvinces }) => {
  if (isHomePagePath(path) && !isMobile) {
    document.getElementsByClassName('honda-family')[0]?.setAttribute('style', 'display:block;');
    document.getElementsByClassName('navbar-wrapper')[0]?.setAttribute('style', 'top: 62px;');
    window.scrollTo(0, 0);
  }
  const isReturningUser = Boolean(Cookies.get(RETURNING_USER_COOKIE));

  if (!isReturningUser) {
    if (!isFunctionalCookiesBlockedWithConsent(cookieConsentProvinces)) {
      Cookies.set(RETURNING_USER_COOKIE, '{}');
    }
    if (isHomePagePath(path)) {
      document.getElementsByClassName('honda-family-overlay-container')[0]?.setAttribute('style', 'display:block;');
    }
  }
};

export default toggleHondaFamilyHeader;
