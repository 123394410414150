/* eslint-disable no-underscore-dangle */
import 'es6-promise/auto';
import 'isomorphic-fetch';
import smoothscroll from 'smoothscroll-polyfill';

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import isBrowserModern from '@honda-canada/sitecore-jss-common/lib/utils/isBrowserModern';
import i18nInit from '@honda-canada/sitecore-jss-common/lib/utils/i18nInit';
import AppRoot from '@honda-canada/sitecore-jss-common/lib/components/AppRoot';
import ErrorBoundary from '@honda-canada/sitecore-jss-common/lib/components/ErrorBoundary';
import { Provider as ReduxProvider } from 'react-redux';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import ReducersProvider from '@honda-canada/sitecore-jss-components/lib/components/ReducersProvider';
import ModelExplorationContextProvider from '@honda-canada/sitecore-jss-components/lib/components/ModelExplorationContext';
import PopupContextProvider from '@honda-canada/sitecore-jss-components/lib/components/Popup/PopupContext';
import ModelFiltersContextProvider from '@honda-canada/sitecore-jss-components/lib/components/ModelFiltersContext';
import UserLocationModal from '@honda-canada/sitecore-jss-components/lib/components/UserLocationModal';
import UserLocationSummary from '@honda-canada/sitecore-jss-components/lib/components/UserLocationSummary';
import { HelmetProvider } from 'react-helmet-async';
import { HondaTheme } from '@honda-canada/design-system-react/lib/honda-theme';
import '@honda-canada/design-system-react/lib/honda-css.css';
import '@honda-canada/embedded-chat/lib/honda-ui.css';
import UserLocationProvider from '@honda-canada/user-location';
import { ThemeProvider } from 'styled-components';
import { GoogleMapsApiKeyProvider } from '@honda-canada/experience-api-client';
import { store } from '@honda-canada/sitecore-jss-components/lib/core/store';
import BottomElementProvider from '@honda-canada/sitecore-jss-components/lib/components/Footer/service/BottomElementProvider';
import componentFactory from './componentFactory';
import updateLanguageLink from './updateLanguageLink';
import toggleHondaFamilyHeader from './importedHeader';
import ApplicationErrorMessage from './ApplicationErrorMessage';

smoothscroll.polyfill();

let renderFunction = ReactDOM.render;

let initLanguage = 'en';

let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');

if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}

if (__JSS_STATE__) {
  renderFunction = ReactDOM.hydrate;
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

const { config } = __JSS_STATE__;

const legacyStyles = `
  footer.honda-footer {
    border-top: 1px solid #d2d2d2;
    position: static;
  }
  .honda-header .navbar .navbar-logo {
    padding-top: 0 !important;
  }
  .header-mobile .icon-close, .header-mobile .icon-menu {
    padding-top: 4px !important;
  }
  #jss-root {
    margin-top: 42px;
    background-color: #ffffff;
    min-height: 600px;
  }
  @media screen and (min-width: 1124px) {
    #jss-root {
      margin-top: 84px;
    }
  }
`;

const renderUserLocationAppInFooter = ({
  setReloadLocationFromCookies,
  setIsUserLocationModalOpen,
  reloadLocationFromCookies,
}) => {
  const locationRootEl = document.getElementById('province-dropdown');

  if (locationRootEl) {
    renderFunction(
      <ErrorBoundary errorMessage={<ApplicationErrorMessage />}>
        <ThemeProvider theme={HondaTheme}>
          <GoogleMapsApiKeyProvider
            env={config?.hondaExperienceApiEnv}
            consumerId={config?.hondaExperienceApiConsumerId}
            consumerName={config?.hondaExperienceApiConsumerName}
          >
            {googleMapsApiKey => (
              <UserLocationProvider
                googleMapsApiKey={googleMapsApiKey}
                forceReload={reloadLocationFromCookies}
                onLocationChanged={() => {
                  setReloadLocationFromCookies(!reloadLocationFromCookies);
                }}
              >
                <UserLocationSummary
                  onChangeLocationClick={() => {
                    setIsUserLocationModalOpen(true);
                  }}
                />
              </UserLocationProvider>
            )}
          </GoogleMapsApiKeyProvider>
        </ThemeProvider>
      </ErrorBoundary>,
      locationRootEl,
    );
  }
};

const App = () => {
  const renderLegacyHeaderAndFooterFeatureFlag = __JSS_STATE__?.viewBag?.featureFlags?.legacyHeaderAndFooter;
  const [reloadLocationFromCookies, setReloadLocationFromCookies] = useState(false);
  const [isUserLocationModalOpen, setIsUserLocationModalOpen] = useState(false);

  useEffect(() => {
    if (renderLegacyHeaderAndFooterFeatureFlag) {
      const styleSheet = document.createElement('style');
      styleSheet.type = 'text/css';
      styleSheet.innerText = legacyStyles;
      document.head.appendChild(styleSheet);

      return () => {
        document.head.removeChild(styleSheet);
      };
    }
  }, [renderLegacyHeaderAndFooterFeatureFlag]);

  toggleHondaFamilyHeader({ cookieConsentProvinces: config?.cookieConsentProvnces });

  useEffect(() => {
    renderUserLocationAppInFooter({
      reloadLocationFromCookies,
      setIsUserLocationModalOpen,
      setReloadLocationFromCookies,
    });
  }, [reloadLocationFromCookies]);

  return (
    <ErrorBoundary errorMessage={<ApplicationErrorMessage />}>
      <ReduxProvider store={store}>
        <HelmetProvider>
          <GoogleMapsApiKeyProvider
            env={config?.hondaExperienceApiEnv}
            consumerId={config?.hondaExperienceApiConsumerId}
            consumerName={config?.hondaExperienceApiConsumerName}
          >
            {googleMapsApiKey => (
              <UserLocationProvider
                googleMapsApiKey={googleMapsApiKey}
                forceReload={reloadLocationFromCookies}
                onLocationChanged={() => setReloadLocationFromCookies(!reloadLocationFromCookies)}
                requestUserEnterLocation={() => setIsUserLocationModalOpen(true)}
              >
                <AppRoot
                  activePlaceholders={renderLegacyHeaderAndFooterFeatureFlag ? ['main'] : ['header', 'main', 'footer']}
                  bottomElementProvider={BottomElementProvider}
                  componentFactory={componentFactory}
                  config={config}
                  errorMessage={<ApplicationErrorMessage is404 />}
                  isUserLocationModalOpen={isUserLocationModalOpen}
                  modelExplorationContextProvider={ModelExplorationContextProvider}
                  popupContextProvider={PopupContextProvider}
                  modelFiltersContextProvider={ModelFiltersContextProvider}
                  path={window.location.pathname}
                  Router={BrowserRouter}
                  ReducersProvider={ReducersProvider}
                  setIsUserLocationModalOpen={setIsUserLocationModalOpen}
                  ssrState={__JSS_STATE__}
                  theme={HondaTheme}
                  updateExternalLanguageLinks={(language, routeLanguageSelectors) => {
                    updateLanguageLink('languageLink', language, routeLanguageSelectors);
                  }}
                  userLocationModal={UserLocationModal}
                />
              </UserLocationProvider>
            )}
          </GoogleMapsApiKeyProvider>
        </HelmetProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
};

const renderApp = async () => {
  const dictionary = __JSS_STATE__?.viewBag?.dictionary;
  const rootElement = document.getElementById('jss-root');
  await i18nInit(initLanguage, dictionary, config);

  renderFunction(<App />, rootElement);
};

if (isBrowserModern()) {
  renderApp();
} else {
  import('./polyfills').then(renderApp);
}
