/**
 * @param {string} id id of the language toggle link in the MVC header
 * @param {string} language Current language of the Sitecore site
 * @param {object} languageSelectors From the Sitecore site's state
 */

const updateLanguageLink = (id, language, languageSelectors) => {
  const element = document.getElementById(id);
  const targetLanguage = languageSelectors.find(selector => selector.code !== language);

  // 404 errors have /sitecore in the languageSelector url, for some reason.
  if (!element || targetLanguage?.url.includes('/sitecore')) return;

  element.href = targetLanguage?.url;
};

export default updateLanguageLink;
